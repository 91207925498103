@import url("https://fonts.googleapis.com/css?family=Open+Sans");

/* :root {
  color: #565656;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  line-height: 1.7;
}

body {
  background-color: #eaeaea;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.wrapper {
  background-color: white;
  width: 400px;
  padding: 2rem;
  box-shadow: 0 1.5rem 1rem -1rem rgba(0, 0, 0, 0.1);
  border-radius: 0.3rem;
} */

.input {
  width: 100%;
  height: 45px;
  padding: 0 1rem;
  margin-top: 1rem;
  box-sizing: border-box;
  font: inherit;
  border-radius: 0.2rem;
  border: 2px solid #d4d5d6;
  color: #565656;
  -webkit-appearance: none;
}

.input:focus {
  border-color: cornflowerblue;
  outline: none;
}

.input.has-error {
  border-color: tomato;
}

.error {
  margin: 0;
  font-size: 90%;
  color: tomato;
}

.tag-item {
  background-color: #d4d5d6;
  display: inline-block;
  font-size: 14px;
  border-radius: 30px;
  height: 30px;
  padding: 0 4px 0 1rem;
  display: inline-flex;
  align-items: center;
  margin: 0 0.3rem 0.3rem 0;
}

.tag-item > .button {
  background-color: white;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  font: inherit;
  margin-left: 10px;
  font-weight: bold;
  padding: 0;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}


.nav-tabs .nav-item .nav-link {
  background-color:None;
  color: #FFF;
  border-bottom: white; 
  
}

.nav-tabs .nav-item .nav-link.active {
  background-color:#343a40;
  color: white;
  border-bottom: none;
  
}

.my-modal .modal-content {
  background-color:#343a40;
  /* background-color:#181719; */
}

.tag-pill {
  border-radius: 5px;
  background-color: "red";
}

div.my_scroll {
  overflow-y: scroll;
  height: 100%;
}


div.my-nice-scrollbar::-webkit-scrollbar {
  /* display: none; */
  width: 20px;
}

div.my-nice-scrollbar::-webkit-scrollbar-track{
  background: #b3b3b3;
}

div.my-nice-scrollbar::-webkit-scrollbar-thumb{
  background: #666666;
  border-radius: 5px;
}


/* div.my_scroll:hover {
  overflow-y: scroll;
  height: 100%;
} */

